<template>
    <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" :data-target="`#${dataTarget}`" :aria-controls="ariaControls" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
</template>

<script>
export default {
    name: "NavbarToggler",
    props: {
        dataTarget: {
            type: String
        },
        ariaControls: {
            type: String
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar-toggler {
    margin-left: auto;

    border-color: transparent;
    color: #333333;
    width: 30px;
    height: 20px;
    padding: 0px;
    border: 0px;
    line-height: unset;
    position: relative;

    &:focus {
        border-color: transparent !important;
		outline: 0;
    }

    &::before,
    &::after {
        content: '';
		background-color: #fff;
		position: absolute;
		width: 100%;
		height: 2px;
		left: 0;
		-webkit-transition: all 0.8s ease 0s;
		-o-transition: all 0.8s ease 0s;
		transition: all 0.8s ease 0s;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }

    span.navbar-toggler-icon {
        display: inline-block;
		width: 100%;
		height: 2px;
		vertical-align: middle;
		content: unset;
		background: unset;
		background-size: unset;
		background-image: unset;
		background-color: #fff;
		-webkit-transition: all 0.8s ease 0s;
		-o-transition: all 0.8s ease 0s;
		transition: all 0.8s ease 0s;
		position: absolute;
		top: 9px;
		left: 0;
    }

    &:not(.collapsed),
    &.active {
        &:before {
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            top: 9px;
        }

        span {
            -webkit-transform: scale(0);
            -ms-transform: scale(0);
            transform: scale(0);
        }

        &:after {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            bottom: 9px;
        }
    }
}
</style>